import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceService } from '../../services/balances/client-balance.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { GiftCardInformationService } from 'src/app/services/gift-card/gift.card.information.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from '../attachments/attachments.component';
import { Attachment } from 'src/app/models/classes/transactions/attachment';
import { TransactionType } from 'src/app/models/enums/transaction-type';
import { Transaction2Service } from 'src/app/dashboard/transaction/service/transaction.service';
import { ResponseState } from 'src/app/models/support/response-state';
import { ChooseAccountsComponent } from '../choose-accounts/choose-accounts.component';
import { ModalUploadAttachementsComponent } from '../modal-upload-attachements/modal-upload-attachements.component';
import { PettyCashItem } from 'src/app/models/classes/transactions/petty-cash-items';
import { PettyCashItemsService } from 'src/app/services/transaction/petty-cash-items.service';
import { PettyCashItemsSearchCriteria } from 'src/app/models/search-criterias/petty-cash-items-search-criteria';

@Component({
  selector: 'app-modal-add-update-petty-cash',
  templateUrl: './modal-add-update-petty-cash.component.html',
  styleUrls: ['./modal-add-update-petty-cash.component.scss'],
  providers: [DatePipe],
})
export class ModalAddUpdatePettyCashComponent implements OnInit {

  @Input() GiftCardInformationId: number;
  @Input() GiftCardCode: string;
  @Output() Added = new EventEmitter<boolean>();
  @ViewChild('attachmentsComponent', { static: false })
  attachmentsComponent: AttachmentsComponent;
  public attachmentsFiles: Attachment[] = [];
  Attachments: UntypedFormArray;
  GForm: UntypedFormGroup;
  TransactionsPayments: UntypedFormArray;
  Descreption: string;
  LoadingEditExpenseDescription: boolean;
  IsActive: boolean = false;
  freeTextGetPettyCashItems: string = null;
  LoadingMakeTransactionValidation: boolean = false;
  LoadingGetPettyCashItems: boolean = false;

  public accountPayments: any;
  TaxTreatment: boolean;


  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private ngbModal: NgbModal,
    private pettyCashItemsService: PettyCashItemsService,
    private transactionService: Transaction2Service,
    public toaster: ToastrService,
    public giftCardInformationService: GiftCardInformationService,
    public clientBalanceService: ClientBalanceService
  ) { }

  ngOnInit(): void {
    this.GFormDate();
    this.MakeTransactionValidation();
    this.GetPettyCashItems(true);
    this.GetByIdAccountSetup();
  }
  CreateData() {
    this.Create();
  }
  PettyCashItems: PettyCashItem[];
  GetPettyCashItems(firstTime: boolean) {

    this.LoadingGetPettyCashItems = firstTime ? true : false;

    let model: PettyCashItemsSearchCriteria = new PettyCashItemsSearchCriteria();
    model.ForAutoComplete = 1;
    model.AccountSetupId = this.sharedService.AccountSetupId;
    model.PagingEnabled = true;
    model.PageNumber = 0;
    model.PageSize = 2;
    model.FreeText = this.freeTextGetPettyCashItems;

    this.pettyCashItemsService.searchPettyCashItems(model).subscribe((response: any) => {
      if (response) {
        // if (response.State == ResponseState.Success) {
        this.PettyCashItems = response.PettyCashItems;
      }
      this.LoadingGetPettyCashItems = false;
    });
  }
  AccountSetup; AllowUseJournals
  GetByIdAccountSetup() {
    this.transactionService.GetByIdAccountSetup(this.sharedService.AccountSetupId).subscribe((response: any) => {
      if (response) {
        if (response.State == ResponseState.Success) {
          this.AccountSetup = response.Result;

          this.AllowUseJournals = (this.AccountSetup?.AllowUseJournals);
        }
      }
    });
  }
  /*======== MakeTransactionValidation ====================================*/
  MessageValidation;
  MakeTransactionValidation() {
    this.LoadingMakeTransactionValidation = true;
    this.transactionService.MakeTransactionValidation({ AccountSetupId: this.sharedService.AccountSetupId }).subscribe((response: any) => {

      if (response.State == ResponseState.Success) {
        this.MessageValidation = null;
        this.IsActive = true;
      }
      else {
        this.MessageValidation = response.Message;
        this.IsActive = false;
      }
      this.LoadingMakeTransactionValidation = false;
    });
  }




  openModalUploadAttachements() {

    const modalRef = this.ngbModal.open(ModalUploadAttachementsComponent, {
      windowClass: 'myCustomModalClasMinWidth70',
    });

    modalRef.componentInstance.attachmentsFiles = this.attachmentsFiles;
    modalRef.componentInstance.CloseModal.subscribe((result: Attachment[]) => {

      if (result) {
        this.attachmentsFiles = result;
      }

    });

  }
  data;
  getTransactionHeaderData(e) {

    this.data = e;

    this.GForm.patchValue({
      TransfereToCashierId: this.data.CashierId,
      EmployeeId: this.data.EmployeeId,
    });


    this.GForm.get('Transaction').patchValue({
      EnteredById: this.data.EmployeeId,
      CashierDailyId: this.data.CashierDailyId,
      CashierId: this.data.CashierId,
      CashierShiftId: this.data.CashierShiftId,

    });

  }

  SetValueAmount(event: any): void {


    setTimeout(() => {
      const AmountBeforeTax = this.getParsedAmount(event.target.value);
      this.updateTransactionFields(AmountBeforeTax);
    }, 800);

  }

  SetValueNetAmount(event: any): void {


    setTimeout(() => {
      const AmountAfterTax = this.getParsedAmount(event.target.value);
      const AmountBeforeTax = (AmountAfterTax / 1.15)
      /* parseFloat((AmountAfterTax / 1.15).toFixed(this.sharedService.GetFractionRoundCount)) */;
      this.updateTransactionFields(AmountBeforeTax);
    }, 800);

  }

  toggleTaxTreatment(event: any): void {

    this.TaxTreatment = event.target.checked;
    const amountBeforeTax = this.GForm.get('Transaction').get('PriceBeforeDiscount').value;
    this.updateTransactionFields(amountBeforeTax);
  }

  updateTransactionFields(AmountBeforeTax: number): void {

    const TotalNetPrice = this.TaxTreatment ? AmountBeforeTax * 1.15 : AmountBeforeTax;
    const roundedTotalNetPrice = TotalNetPrice/*
      parseFloat(TotalNetPrice.toFixed(this.sharedService.GetFractionRoundCount)) */;

    this.GForm.get('Transaction').get('TotalNetPrice').setValue(roundedTotalNetPrice, { emitEvent: false });
    this.GForm.get('Transaction').get('PriceBeforeDiscount').setValue(AmountBeforeTax, { emitEvent: false });
    this.GForm.get('Transaction').get('PriceAfterDiscount').setValue(AmountBeforeTax, { emitEvent: false });
    this.GForm.get('Transaction').get('TaxValue').setValue(roundedTotalNetPrice - AmountBeforeTax);

    const controlArray = <UntypedFormArray>this.GForm.get('Transaction').get('TransactionsPayments');
    controlArray.controls[0].get('Amount').setValue(roundedTotalNetPrice);
  }

  getParsedAmount(value: any): number {

    let amount = parseFloat(value);
    return isNaN(amount) ? 0 : amount/* parseFloat(amount.toFixed(this.sharedService.GetFractionRoundCount)) */;
  }

  openModalAccounts() {

    const modalRef = this.ngbModal.open(ChooseAccountsComponent);
    modalRef.componentInstance.isViewPlus = true;

    modalRef.componentInstance.emitService.subscribe((emmitedValue) => {

      this.GForm.get('Transaction').get('PettyCashAccountId').patchValue(emmitedValue.Id)
      this.GForm.get('Transaction').get('PettyCashAccountName').patchValue(emmitedValue.NameAr)

    });
  }
  onSearch(e) {
    this.freeTextGetPettyCashItems = e?.term ?? null;
    this.GetPettyCashItems(false);
  }

  btnLoading = false;

  Create() {
    this.btnLoading = true;

    if (this.attachmentsFiles) {

      this.Attachments = this.GForm.get(
        'Transaction'
      ).get('Attachments') as UntypedFormArray;

      this.Attachments.clear();

      this.attachmentsFiles.forEach((attachmentsFile) => {
        this.Attachments.push(
          this.fb.group({
            AttachmentId: attachmentsFile.Id,
            BookingId: attachmentsFile.BookingId,
            TransactionId: attachmentsFile.TransactionId,
            FolderName: attachmentsFile.FolderName,
            FileName: attachmentsFile.FileName,
            FileType: attachmentsFile.FileType,
            FilePath: attachmentsFile.FilePath,
            FileNotes: attachmentsFile.FileNotes,
          })
        );
      });
    }

    this.transactionService.CreateTransactions(this.GForm.value).subscribe((response: any) => {
      if (response.State == ResponseState.Success) {
        this.sharedService.ToastrSuccess(response.Message);
        this.sharedService.HideModal();
        this.GForm.reset();
        this.Added.emit(true);
        this.closeModal();
        this.btnLoading = false;
      }
      else {
        this.sharedService.ToastrError(response.Message);
        this.btnLoading = false;

      }
    });

  }

  GFormDate() {
    this.GForm = this.fb.group({
      TransfereToCashierId: 0,
      EmployeeId: 0,
      Transaction: this.fb.group({
        Id: 0,
        PettyCashItemsId: null,
        TransactionType: TransactionType.PettyCash,
        TotalNetPrice: 0,
        Descreption: [''],
        CashierId: 0,
        CashierShiftId: 0,
        ClientId: null,
        PettyCashAccountId: '',
        PettyCashAccountName: '',
        TaxValue: '',
        TaxTreatment: '',
        PriceBeforeDiscount: '',
        PriceAfterDiscount: '',
        EnteredById: 0,
        BookingId: null,
        CashierDailyId: 0,
        TransactionStatus: 3,
        AccountSetupId: this.sharedService.AccountSetupId,
        CompanyId: this.sharedService.CompanyId,
        TransactionsPayments: this.fb.array([this.AddTransactionsPayments()]),
        Attachments: this.fb.array([])
      }),
    })
  }

  AddTransactionsPayments() {
    return this.fb.group({
      TransactionId: 0,
      PaymentTypeId: 1,
      Amount: 0,
      AmountInDefault: 0,
      CurrencyId: 0,
      AvalableCredit: null,
      AvalableClientPaymentDown: null,
      ShowAvalableCredit: false,
      ShowAvalableClientPaymentDown: null,
      showGiftcardCode: false,
      availablecredit: null,
      giftCardChoosed: false,
      voucherChoosed: false,
      giftCardCode: null,
      Code: null,
      enableCheckButton: null,
      showVisaDetailsButton: false,
      loyalityMonyChoosed: false,
      clientDownPaymentChoosed: false,
      VisaTypeId: null,
      VisaNumber: null,
      VisaTypeName: null,
      CurrencyFactor: 1,
      PaymentMethodId: 0,

    })
  }
  onAttachmentsComponentInitialized(attachmentsComponent: AttachmentsComponent) {
    this.attachmentsComponent = attachmentsComponent;


    if (this.attachmentsFiles && this.attachmentsFiles.length > 0) {
      this.attachmentsComponent.update(
        this.attachmentsFiles.map(
          ({
            Id,
            BookingId,
            TransactionId,
            JournalId,

            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          }) => ({
            Id,
            BookingId,
            TransactionId,
            JournalId,
            FileName,
            FolderName,
            FileType,
            FilePath,
            FileNotes,
          })
        )
      );
    }
  }


  divName;
  printPage() {
    this.divName = 'printClientGiftCardInfo';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.attachmentsFiles = this.attachmentsComponent.attachmentsFiles;
  }

  closeModal() {
    this.activeModal.close('Close click');
  }


}
